import { useState, useEffect } from 'react'
import { ethers } from 'ethers'
import UIkit from 'uikit'
import Icons from 'uikit/dist/js/uikit-icons'
import MetaMask from './static/metamask.svg'
import MP from '../../blockchain/artifacts/contracts/MetaPumps.sol/MetaPumps.json'

UIkit.use(Icons)

function App() {
    if (window.ethereum === undefined) {
        return (
            <div>
                <img data-src={MetaMask} width='128' uk-img='true' />
                <h2 className='uk-heading-small'>INSTALL METAMASK</h2>
                <a
                className='uk-button uk-button-primary'
                href='https://metamask.io/download/'
                target='_blank'
                rel='noopener noreferrer nofollow'>
                DOWNLOAD <span className='uk-margin-left-small' uk-icon='chevron-down'></span>
                </a>
            </div>
        )
    }
    
    const [userAddress, setUserAddress] = useState(0)
    const [soldOut, setSoldOut] = useState(false)
    const [status, setStatus] = useState(0)
    const CID = {
        1: 'Ethereum',
        3: 'Ropsten',
        4: 'Rinkeby',
        31337: 'Hardhat'
    }

    useEffect(() => {
        if (userAddress > 0) {
            window.ethereum.on('chainChanged', () => {
                window.location.reload();
            });
            window.ethereum.on('accountsChanged', () => {
                window.location.reload();
            });
        }
    });

    async function connectWallet() {
        const provider = new ethers.providers.Web3Provider(window.ethereum, 'any')
        const signer = provider.getSigner(0)
        const contract = new ethers.Contract(process.env.CONTRACT_ADDRESS, MP.abi, signer);
        await provider.getNetwork()
            .then((n) => {
                if (n.chainId != process.env.CHAIN_ID) {
                    UIkit.notification(`Connect your wallet to the ${CID[process.env.CHAIN_ID]} network`);
                    throw new Error(`Connect your wallet to the ${CID[process.env.CHAIN_ID]} network`);
                }
            });
        await provider.send('eth_requestAccounts', [])
            .then(a => setUserAddress(a));
        await contract.status()
              .then(s => setStatus(s));
        await contract.totalSupply()
            .then((ts) => {
                if (ts >= 9600) setSoldOut(true);
            });
    }
    
    async function mintTokens() {
        const provider = new ethers.providers.Web3Provider(window.ethereum, 'any')
        const signer = provider.getSigner(0)
        const contract = new ethers.Contract(process.env.CONTRACT_ADDRESS, MP.abi, signer);
        let qty = document.getElementById('mpQuantity').value;
        let price;
        if (status == 1) price = 0.05;
        else price = 0.06;
        const minted = await contract.mint(qty, {value: ethers.utils.parseEther((qty * price).toString())})
          .then(res => res.wait())
          .catch(e => alert(e.message));
        if (minted) {
            window.location.reload();
        }
    }

    if (userAddress === 0) {
        return (
            <div>
                <h2 className='uk-heading-small'>BUY A METAPUMP</h2>
                <button
                className='uk-button uk-button-primary'
                type='button'
                onClick={connectWallet}
                >
                CONNECT WALLET
                </button>
            </div>
        )
    }

    if (soldOut == true) {
        return (
            <div>
                <h2 className='uk-heading-small'>SOLD OUT</h2>
                <p className='uk-text-large uk-light'>TRADE ON OPENSEA</p>
                <a
                className='uk-button uk-button-primary'
                href={"http://".concat("opensea.io/", process.env.CONTRACT_ADDRESS)}
                target='_blank'
                rel='noopener noreferrer nofollow'>
                VIEW COLLECTION
                </a>
            </div>
        )
    }

    if (status < 1) {
        return (
            <div>
                <h2 className='uk-heading-small'>COMING SOON</h2>
                <a id="joinList" href="https://docs.google.com/forms/d/1vslrk2gU2r0ZEMAHSTsF7QbRA7hZK-9mBlRB39dzQhE" title="join whitelist" target='_blank' rel='noopener noreferrer nofollow' className='uk-button uk-button-primary'>JOIN OUR WHITELIST</a>
            </div>
        )
    }

    return (
        <div>
            <h2 className='uk-heading-small'>BUY A METAPUMP</h2>
            {status < 2 ? (
                <p className='uk-light'>Minting is now open for whitelisted members.</p>
            ) : (
                <p className='uk-light'>Minting is now open for the public.</p>
            )}
            <input id='mpQuantity' type='number' className='uk-input uk-width-small uk-margin-auto uk-form-large uk-text-warning uk-text-center uk-display-block' min='1' max='5' step='1' placeholder='How many?'/>
            <button
            className='uk-button uk-button-primary uk-margin-top uk-margin-auto uk-display-block'
            type='button'
            onClick={mintTokens}
            >
            MINT NOW
            </button>
        </div>
    )

}

export default App